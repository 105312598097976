import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Small } from '@bootstrap-styled/v4'
import breakpoints from '../../util/style/breakpoints'
import { H4, P } from '../Typography'
import Label from '../UI/Label'

const PreviewImageWrapper = styled.div`
  position: relative;
  height: 250px;

  ${breakpoints.md`
      height: 184px;
  `};

  ${breakpoints.lg`
      height: 250px;
  `};

  .image-label {
    position: absolute;
    color: white;
    background: ${(props) => props.theme.$gray};
    bottom: 0;
    right: 0;
    padding: 1rem;

    &__big {
      font-size: 1.5rem;
    }
    p {
      margin: 0;
    }
  }
`

const PreviewCard = ({
  intl,
  header,
  subHeader,
  previewImage,
  imageLabel,
  label,
  rentOrBuy,
}) => {
  const sanityConfig = {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }
  const previewImageData = getGatsbyImageData(
    previewImage,
    { width: 376 },
    sanityConfig
  )

  return (
    <>
      {previewImageData && (
        <PreviewImageWrapper className="mb-4 negative-margin-mobile">
          <GatsbyImage
            alt={`afbeelding-${header}`}
            image={previewImageData}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          />
          {imageLabel && (
            <div className="image-label">
              <P>
                <span className="image-label__big font-weight-normal">
                  {imageLabel}
                </span>
                {rentOrBuy && (
                  <>
                    {rentOrBuy === 'RENT_PROPERTY' && (
                      <span className="font-weight-light">
                        {' '}
                        {intl.formatMessage({ id: 'per_month' })}
                      </span>
                    )}
                    {rentOrBuy === 'BUY_PROPERTY' && (
                      <span className="font-weight-light">
                        {' '}
                        {intl.formatMessage({ id: 'COST_BUYER' })}
                      </span>
                    )}
                  </>
                )}
              </P>
            </div>
          )}
        </PreviewImageWrapper>
      )}
      {label && (
        <div className="mb-4">
          <Label text={label} />
        </div>
      )}
      {header && (
        <H4 color="#b98752" className="mb-4">
          {header}
        </H4>
      )}
      {subHeader && (
        <>
          <Small color="muted" className="text-uppercase font-weight-medium">
            {subHeader}
          </Small>
        </>
      )}
    </>
  )
}

PreviewCard.propTypes = {}

export default injectIntl(PreviewCard)
