import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { Link } from '../Link'
import Icon from '../Icons'

const Container = styled.div`
  min-height: 4rem;
  color: ${(props) => props.theme['$gray-lightest']};
  position: relative;

  a {
    color: ${(props) => props.theme['$gray-lightest']};
    &.active {
      color: white;
    }
  }

  .flip {
    transform: rotate(180deg);
  }

  .prev {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .page-selection {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
  }

  .next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

const BlogPagination = ({
  intl,
  previousPagePath,
  nextPagePath,
  numberOfPages,
  currentPath,
}) => (
  <>
    {numberOfPages && numberOfPages >= 2 && (
      <>
        <hr />
        <Container>
          <div className="prev d-flex align-items-center">
            {previousPagePath ? (
              <Link
                className="font-weight-medium d-flex align-items-center"
                to={previousPagePath}
              >
                <Icon fill="#888888" width="20" className="flip" name="arrow" />
                <span className="pl-2 d-none d-sm-block">{intl.formatMessage({ id: 'PREVIOUS' })}</span>
              </Link>
            ) : null}
          </div>
          <div className="page-selection font-weight-medium">
            {Array.from({ length: numberOfPages }, (_, i) => {
              if (i === 0) {
                return (
                  <Link to={currentPath} className="mr-4" key={`page-first-${i + 1}`}>
                    {i + 1}
                  </Link>
                )
              }
              if (i <= 2) {
                return (
                  <Link
                    to={`${currentPath}/${i + 1}`}
                    className="mr-4"
                    key={`page${i + 1}`}
                  >
                    {i + 1}
                  </Link>
                )
              }
              if (i <= 4) {
                return (
                  <Link
                    to={`${currentPath}/${i + 1}`}
                    className="mr-4 d-none d-sm-inline"
                    key={`page${i + 1}`}
                  >
                    {i + 1}
                  </Link>
                )
              }
              if (i > 4 && i + 1 === numberOfPages) {
                return (
                  <div className="d-inline" key={`page-last-${i + 1}`}>
                    <span className="mr-4">...</span>
                    <Link
                    to={`${currentPath}/${i + 1}`}
                    className="mr-4"
                    key={`page${i + 1}`}
                  >
                      {i + 1}
                    </Link>
                  </div>
                )
              }
              return <></>
            })}
          </div>
          <div className="next d-flex align-items-center">
            {nextPagePath ? (
              <Link
                className="font-weight-medium d-flex align-items-center"
                to={nextPagePath}
              >
                <span className="pr-2 d-none d-sm-block">{intl.formatMessage({ id: 'NEXT' })}</span>
                <Icon fill="#888888" width="20" name="arrow" />
              </Link>
            ) : null}
          </div>
        </Container>
        <hr />
      </>
    )}
  </>
)

export default injectIntl(BlogPagination)
