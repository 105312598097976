import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { slugify } from '../../util/js/slugify'
import { Link } from '../Link'

const FilterBtn = styled(Link)`
  color: ${(props) => props.theme['$gray-lightest']};

  &.active {
  color: white;
  }
`

const BlogCategoryFilter = ({ intl, children }) => (
  <StaticQuery
    query={graphql`
      query {
        allSanityBlogCategory {
          edges {
            node {
              id
              category
            }
          }
        }
      }
    `}
    render={(data) => {
      const filters = data.allSanityBlogCategory.edges

      return (
        <>
          <FilterBtn className="font-weight-medium mr-md-4" to="/blog">
            Alles
          </FilterBtn>
          {filters &&
            filters.map((filter) => (
              <FilterBtn key={filter.node.id} partiallyActive to={`/blog/${slugify(filter.node.category)}`} className="font-weight-medium mr-md-4">
                {filter.node.category}
              </FilterBtn>
            ))}
        </>
      )
    }}
  />
)

export default injectIntl(BlogCategoryFilter)
