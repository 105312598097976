import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from '../../Link'

const PreviewLink = styled(Link)`
  display: block;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    img {
      opacity: 0.6 !important;
    }
  }
`

const PreviewCardAsLink = ({ children, link }) => (
  <PreviewLink to={link}>{children}</PreviewLink>
)

PreviewCardAsLink.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
}

export default PreviewCardAsLink
