import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { P } from '../../Typography'

const LabelContainer = styled.div`
  display: inline-block;
  p {
    color: white;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    background: #393939;
    text-align: center;
    margin-bottom: 0;
  }
`

const Label = ({ text }) => (
  <LabelContainer>
    <P>{text}</P>
  </LabelContainer>
)

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Label
